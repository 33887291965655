import React from 'react'
import './App.css'
import {Routes,Route} from 'react-router-dom'
import Footer from './components/Footer/Footer'
import Terms from './components/Footer/Terms'
import Refund from './components/Footer/Refund'
import Privacy from './components/Footer/Privacy'
import Header from './components/Header/Header'
import HomePage from './components/HomePage/HomePage'
import StoreSection from './components/StoreSection/StoreSection'
import Events from './components/Events/Events'
import AllCourses from './components/AllCourses/AllCourses'
import DetailCourses from './components/DetailCourses/DetailCourses'
import PreviousBootcamp from './components/PreviousBootcamp/PreviousBootcamp'
import AboutUs from './components/AboutUs/AboutUs'

const App = () => {
  return (
    <>
    <Header/>
    <Routes>
      <Route path='/' element={<HomePage/>}/>
      <Route path='/about-us' element={<AboutUs/>} />
      <Route path="terms" element={<Terms/>}/>
      <Route path="refund" element={<Refund/>}/>
      <Route path="privacy" element={<Privacy/>}/>
      <Route path='store' element={<StoreSection/>}/>
      <Route path='courses' element={<AllCourses/>}/>
      <Route path='courses/:courseId' element={<DetailCourses/>} />
      <Route path='events' element={<Events/>}/>
      <Route path='events/:eventId' element={<PreviousBootcamp/>}/>
      <Route path='*' element={<center><h1>Oops! You are Lost Kindly Find A Route Above</h1></center>}/>
    </Routes>
    <Footer/>
    </>
  )
}

export default App