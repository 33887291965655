import React, { useEffect } from "react";
const Refund = () => {
  useEffect(() => {
    window.scrollTo(0, 0);
  }, []);
  return (
    <div className="terms-padding">
      <h2>
        <strong>Refund Policy for Code Sprint under Savna Tech Products Private Limited</strong>
      </h2>

      <h4><strong>Refund Eligibility:</strong></h4>
      <ul>
      <li>
      <p>
      <strong> Before Course Commencement: </strong> Learners may request a refund at any time before the course start date. A processing fee equivalent to 50% of the initial slot booking payment will be deducted during the refund process. This fee is non-refundable.
      </p>
      </li>
      <li>
      <p>
        <strong> After Course Commencement: </strong> Refund requests made after the course has started are not eligible for any refund under any circumstances. Learners are responsible for completing any remaining payments, including installments under auto-pay agreements, if applicable.
      </p>
      </li>
      </ul>

      <h4>Refund Application Process:</h4>
      <ul>
        <li>
            <p>Refund applications must be submitted through the official email: hello@codesprint.in
            </p>
        </li>    
        <li>
            <p>Refunds will be processed within 30 business days from the date of approval.
            </p>
        </li>   
      </ul>

      <h4>Autopay or Mandate Fund Agreements:</h4>
      <ul>
        <li>
            <p>Learners who have set up autopay or mandate fund agreements are solely responsible for managing any cancellation or adjustment charges levied by the service provider. Savna Tech Products Private Limited does not have any authority or influence over third-party service agreements.
            </p>
        </li>    
      </ul>

      <h4>Fee Deadlines:</h4>
      <ul>
        <li>
            <p>Full program fees must be paid within the specified deadlines outlined in the enrollment of the course. Non-compliance with fee deadlines may result in forfeiture of admission and cancellation of the enrollment.
            </p>
        </li>    
      </ul>
      <h4>Policy Modifications:</h4>
      <ul>
        <li>
            <p>Savna Tech Products Private Limited reserves the right to modify the refund policy from time to time. Any updates to the policy will be communicated through official channels, including the company website and learner portal.
            </p>
        </li>    
      </ul>

      <h3>Case Details for Refund:</h3>
      <ul>
      <li>
      <p>
      <strong> Before Course Commencement: </strong> 50% of the initial slot booking payment will be deducted.
      </p>
      </li>
      <li>
      <p>
        <strong> After Course Commencement: </strong> No refund possible.
      </p>
      </li>
      </ul>

      <p>
      This policy aims to provide a learner-centric approach to managing enrollment changes, balancing flexibility with the need to ensure resource planning for program delivery.
For any queries or assistance regarding refunds, learners are encouraged to contact the Savna Tech support team through the official helpdesk.

      </p>
    </div>
  );
};
export default Refund;
